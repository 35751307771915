<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h2>
          Over ons
        </h2>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="mb-4 text-center">
        <p class="font-weight-regular">
        Humanwise<br>
        Woldpoort 1a<br>
        8331 KP Steenwijk<br>
        Nederland<br>
        Helpdesk: 0521 53 58 09<br>
        info@humanwise.nl<br>
        kvk 633 20 444<br>
        </p>
      </v-col>

      <v-col cols="12" class="mb-4 text-center">
        <p class="font-weight-thin">
        2021 Humanwise<br>
        All rights reserved
        </p>
      </v-col>

      <v-col cols="12" class="mb-4 text-center">
        <h2>
          Eigen | Wijs | Ontwikkelen
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "AboutUs",
};
</script>
